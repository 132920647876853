<template>
    <div class="pop-check" ref="popDom">
        <div class="main">
            <div class="check-title">
                <strong>{{ $t('components.TransferHelp.505334-0') }}</strong>
                <span class="close" @click="closePop"></span>
            </div>
            <div class="check-main" ref="main">
                <template v-if="$route.path === '/finance/transfer'">
                    <h3>{{ $t('components.TransferHelp.505334-1') }}</h3>
                    <p>{{ $t('components.TransferHelp.505334-2') }}</p>
                    <p>{{ $t('components.TransferHelp.505334-3') }}</p>
                    <p>{{ $t('components.TransferHelp.505334-4') }}</p>
                    <p>{{ $t('components.TransferHelp.505334-5') }}</p>
                    <p>{{ $t('components.TransferHelp.505334-6') }}</p>
                    <p>{{ $t('components.TransferHelp.505334-7') }}</p>
                    <p>{{ $t('components.TransferHelp.505334-8') }}</p>
                    <h3>{{ $t('components.TransferHelp.505334-9') }}</h3>
                    <p>{{ $t('components.TransferHelp.505334-10') }}
                    </p>
                    <h3>{{ $t('components.TransferHelp.505334-11') }}</h3>
                    <p>{{ $t('components.TransferHelp.505334-12') }}</p>

                    <h3>{{ $t('components.TransferHelp.505334-13') }}</h3>
                    <p>{{ $t('components.TransferHelp.505334-14') }}</p>
                    <p>{{ $t('components.TransferHelp.505334-15') }}</p>
                    <p>{{ $t('components.TransferHelp.505334-16') }}</p>
                    <p>{{ $t('components.TransferHelp.505334-17') }}</p>
                    <p>{{ $t('components.TransferHelp.505334-18') }}</p>
                    <h3>{{ $t('components.TransferHelp.505334-19') }}</h3>
                    <p>{{ $t('components.TransferHelp.505334-20') }}</p>
                    <p>{{ $t('components.TransferHelp.505334-21') }}</p>
                    <p>{{ $t('components.TransferHelp.505334-22') }}</p>
                    <p>{{ $t('components.TransferHelp.505334-23') }}</p>
                </template>
                <template v-if="$route.path === '/finance/withdrawal'">
                    <h3>{{ $t('components.TransferHelp.505334-24') }}</h3>
                    <p>{{ $t('components.TransferHelp.505334-25') }}</p>
                    <p>{{ $t('components.TransferHelp.505334-26') }}</p>
                    <p>{{ $t('components.TransferHelp.505334-27') }}</p>
                    <p>{{ $t('components.TransferHelp.505334-28') }}</p>

                    <h3>{{ $t('components.TransferHelp.505334-29') }}</h3>
                    <p>{{ $t('components.TransferHelp.505334-30') }}</p>

                    <h3>{{ $t('components.TransferHelp.505334-31') }}</h3>
                    <p>{{ $t('components.TransferHelp.505334-32') }}</p>
                    <p>{{ $t('components.TransferHelp.505334-33') }}</p>
                    <p>{{ $t('components.TransferHelp.505334-34') }}</p>
                    <p>{{ $t('components.TransferHelp.505334-35') }}</p>
                    <p>{{ $t('components.TransferHelp.505334-36') }}</p>

                    <h3>{{ $t('components.TransferHelp.505334-37') }}</h3>
                    <p>{{ $t('components.TransferHelp.505334-38') }}</p>

                    <h3>{{ $t('components.TransferHelp.505334-39') }}</h3>
                    <p>{{ $t('components.TransferHelp.505334-40') }}</p>

                    <h3>{{ $t('components.TransferHelp.505334-41') }}</h3>
                    <p>{{ $t('components.TransferHelp.505334-42') }}</p>

                    <h3>{{ $t('components.TransferHelp.505334-43') }}</h3>
                    <p>{{ $t('components.TransferHelp.505334-44') }}</p>

                    <p>{{ $t('components.TransferHelp.505334-45') }}</p>

                    <h3>{{ $t('components.TransferHelp.505334-46') }}</h3>
                    <p>{{ $t('components.TransferHelp.505334-47') }}</p>
                    <p>{{ $t('components.TransferHelp.505334-48') }}</p>

                    <h3>{{ $t('components.TransferHelp.505334-49') }}</h3>
                    <p>{{ $t('components.TransferHelp.505334-50') }}
                    </p>

                    <h3>{{ $t('components.TransferHelp.505334-51') }}</h3>
                    <p>{{ $t('components.TransferHelp.505334-52') }}</p>

                    <p>{{ $t('components.TransferHelp.505334-53') }}"{{ $t('components.TransferHelp.505334-54') }}</p>

                    <h3>{{ $t('components.TransferHelp.505334-55') }}</h3>
                    <p>{{ $t('components.TransferHelp.505334-56') }}</p>

                    <h3>{{ $t('components.TransferHelp.505334-57') }}</h3>
                    <p>{{ $t('components.TransferHelp.505334-58') }}</p>

                    <p>{{ $t('components.TransferHelp.505334-16') }}</p>

                    <p>{{ $t('components.TransferHelp.505334-59') }}</p>

                    <p>{{ $t('components.TransferHelp.505334-60') }}</p>

                </template>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        scrollTop: {
            type: Number,
            default: 10
        }
    },
    mounted() {
        this.initTran()
    },
    methods: {
        initTran() {
            this.$refs.main.scrollTop = this.scrollTop
        },
        closePop() {
            this.$emit("update:visible");
            document.body.style = ''
        },
    }
}
</script>
<style lang="scss">
.pop-check {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .main {
        background-color: #fff;
        width: 640px;
        border-radius: 20px;
        overflow: hidden;
    }

    .check-title {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        height: 48px;
        padding: 0 32px;
        background: #179cff;
        position: relative;

        strong {
            font-size: 18px;
            font-weight: normal;
        }

        .close {
            position: absolute;
            top: 15%;
            right: 24px;
            width: 34px;
            height: 34px;
            display: block;
            background: url("@/assets/images/pay/close01.png") no-repeat center center;
            background-size: 56%;
            cursor: pointer;
            transition: all 0.4s;

            &:hover {
                transform: rotate(-90deg);
            }
        }
    }
}

.check-main {
    padding: 10px 30px 30px;
    overflow-y: auto;
    height: 400px;

    &::-webkit-scrollbar {
        width: 6px;
        height: 0;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0);
    }

    h3 {
        margin: 20px 0 10px;
    }
}
</style>