<template>
  <div class="yx-wid-12 vipCenter">
    <div class="user-left" v-if="userInfo">
      <!-- 头像部分 -->
      <div class="userInfo">
        <div class="photo" @click="setPhoto">
          <img :src="userInfo.photoUrl" />
        </div>
        <div class="name">
          <span class="text">{{ userInfo.userName }}</span>
          <span class="level">
            <img v-if="userInfo && userInfo.levelId >= 0" :src="require('@/assets/images/mine/level/vip' +
              userInfo.levelId +
              '.webp')
              " />
          </span>
        </div>
        <p class="time" v-if="userInfo && baseInfo && baseInfo[2]">
          {{ $t('mine.index.252217-0') }}{{ baseInfo[2].configValue }}{{ userInfo.joinDays }}{{ $t('mine.index.252217-1')
          }}
        </p>
        <ul>
          <li :class="{ hover: isHover === '/finance/deposit' }" @click="$router.push('/finance/deposit')">
            <span class="icon">
              <img src="@/assets/images/mine/icon01.png" class="mr" />
              <img src="@/assets/images/mine/icon01-1.png" class="xz" />
            </span>
            <p>{{ $t('mine.index.252217-2') }}</p>
          </li>
          <li :class="{ hover: isHover === '/finance/transfer' }" @click="$router.push('/finance/transfer')">
            <span class="icon">
              <img src="@/assets/images/mine/icon02.png" class="mr" />
              <img src="@/assets/images/mine/icon02-1.png" class="xz" />
            </span>
            <p>{{ $t('mine.index.252217-3') }}</p>
          </li>
          <li :class="{ hover: isHover === '/finance/withdrawal' }" @click="$router.push('/finance/withdrawal')">
            <span class="icon">
              <img src="@/assets/images/mine/icon03.png" class="mr" />
              <img src="@/assets/images/mine/icon03-1.png" class="xz" />
            </span>
            <p>{{ $t('mine.index.252217-4') }}</p>
          </li>
        </ul>
      </div>
      <!-- menus -->
      <div class="user-nav">
        <ul v-for="(item, index) in menus" :key="index">
          <li v-for="(row, ind) in item" :key="ind" :class="{ hover: row.aid === menuInd }" @click="tabMenu(row)">
            <span class="icon">
              <img :src="row.icon" class="mr" />
              <img :src="row.iconHover" class="xz" />
            </span>
            <span class="text">{{ row.name }}</span>
            <span class="message" v-if="row.aid === 8 && message > 0">{{
              message
            }}</span>
          </li>
        </ul>
      </div>
    </div>
    <router-view></router-view>
    <div class="user-right" v-if="isShowRight">
      <!-- {{ $t('mine.index.252217-40') }} -->
      <template v-if="['/mine/userInfo'].includes($route.path)">
        <!-- {{ $t('mine.index.252217-5') }} -->
        <div class="user-jv">
          <div class="title">
            <span class="text">{{ $t('mine.index.252217-5') }}</span>
            <span class="more" @click="$router.push('/record/betRecord')">{{ $t('mine.index.252217-6') }}<i></i></span>
          </div>
          <ul class="pay-jl-list" v-if="recordList && recordList.length">
            <li v-for="(item, index) in recordList" :key="index">
              <span class="name">{{ item.gameName }}</span>
              <p class="row">
                <span class="left" v-if="item.validBetAmount">{{
                  item.validBetAmount.toFixed(2)
                }}</span>
                <span class="right">{{ getState(item.status) }}</span>
              </p>
            </li>
          </ul>
          <div v-else class="user-jv-node">{{ $t('mine.index.252217-7') }}</div>
        </div>

        <!-- 卡 -->
        <div class="user-jv user-cark" v-if="['/mine/userInfo'].includes($route.path)">
          <div class="cark-title">
            <ul>
              <li v-for="(item, index) in bankListMenu" :key="index" :class="{ hover: carkInd === index }"
                @click="tabBankList(index)">
                {{ item.name }}
              </li>
            </ul>
          </div>
          <div class="bankList-right">
            {{ carkInd === 1 ? $t('mine.index.252217-8') : $t('mine.index.252217-9') }}
            <span class="text-blue" @click="$router.push(bankListMenu[carkInd].moreLink)">{{ $t('mine.index.252217-6')
            }}</span>
          </div>
          <div class="card-list" v-if="bankList && bankList.length">
            <ul>
              <li v-for="(item, index) in bankList" :key="index" :class="{ usdt: carkInd === 1 }">
                <img :src="item.iconUrl" class="sec-img" />
                <div class="sec">
                  <span class="sec-title">{{ item.bankName }}</span>
                  <p class="sec-text">{{ item.cardType }}</p>
                </div>
                <div class="sec-bankNo">{{ accountShow(item.bankcardNo) }}</div>
              </li>
            </ul>
          </div>
          <div class="user-jv-node" v-else>
            {{ $t('mine.index.252217-10') }}<span @click="$router.push(bankListMenu[carkInd].addLink)">{{
              $t('mine.index.252217-11') }}</span>
          </div>
        </div>
      </template>
      <!-- {{ $t('mine.index.252217-2') }} -->
      <template v-if="['/finance/deposit'].includes($route.path)">
        <!-- {{ $t('mine.index.252217-12') }} -->
        <div class="user-jv">
          <div class="title">
            <span class="text">{{ $t('mine.index.252217-12') }}</span>
            <span class="more" @click="$router.push('/record/transRecord')">{{ $t('mine.index.252217-6') }}<i></i></span>
          </div>
          <ul class="pay-jl-list" v-if="ckList && ckList.length">
            <li v-for="(item, index) in ckList" :key="index">
              <span class="name">{{ item.rechargeTypeName }}</span>
              <p class="row">
                <span class="left" v-if="item.rechargeMoney">{{
                  item.rechargeMoney.toFixed(2)
                }}</span>
                <span class="right">{{ item.statusName }}</span>
              </p>
            </li>
          </ul>
          <div v-else class="user-jv-node">{{ $t('mine.index.252217-13') }}</div>
        </div>
        <!--  {{ $t('mine.index.252217-14') }} -->
        <div class="user-jv user-cark">
          <div class="title">
            <span class="text">{{ $t('mine.index.252217-14') }}</span>
            <span class="more" @click="$router.push('/activity/discount')">{{ $t('mine.index.252217-6') }}<i></i></span>
          </div>
          <div class="discount-list">
            <ul v-if="activityList">
              <li v-for="(item, index) in activityList" :key="index" @click="
                $router.push('/activity/activityLandPage?type=' + item.id)
                ">
                <img :src="item.listImgUrl" />
              </li>
            </ul>
            <div v-else class="user-jv-node">{{ $t('mine.index.252217-15') }}</div>
          </div>
        </div>
      </template>
      <!-- {{ $t('mine.index.252217-3') }} -->
      <template v-if="['/finance/transfer'].includes($route.fullPath)">
        <!-- {{ $t('mine.index.252217-16') }} -->
        <div class="user-jv">
          <div class="title">
            <span class="text">{{ $t('mine.index.252217-16') }} </span>
            <span class="more" @click="$router.push('/record/transRecord?type=transfer')">{{ $t('mine.index.252217-6')
            }}<i></i></span>
          </div>
          <ul class="pay-jl-list" v-if="ckList && ckList.length">
            <li v-for="(item, index) in ckList" :key="index">
              <span class="name">
                {{ convertType(item.convertType) }}
                <!-- {{ $t('mine.index.252217-2') }} <img src="@/assets/images/mine/icon55.png" />{{ $t('mine.index.252217-2') }} -->
              </span>
              <p class="row">
                <span class="left" v-if="item.convertMoney">{{
                  item.convertMoney.toFixed(2)
                }}</span>
                <span class="right">{{ item.statusName }}</span>
              </p>
            </li>
          </ul>
          <div v-else class="user-jv-node">{{ $t('mine.index.252217-17') }}</div>
        </div>
        <!--  {{ $t('mine.index.252217-14') }} -->
        <div class="user-jv user-cark">
          <div class="title">
            <span class="text">{{ $t('mine.index.252217-18') }}</span>
            <span class="more" @click="openHelp(0)">{{ $t('mine.index.252217-6') }}<i></i></span>
          </div>
          <div class="help-list">
            <ul>
              <li @click="openHelp(30)">{{ $t('mine.index.252217-19') }}</li>
              <li @click="openHelp(240)">{{ $t('mine.index.252217-20') }}</li>
              <li @click="openHelp(380)">{{ $t('mine.index.252217-22') }}</li>
              <li @click="openHelp(380)">{{ $t('mine.index.252217-23') }}</li>
            </ul>
          </div>
        </div>
      </template>
      <!-- {{ $t('mine.index.252217-4') }} -->
      <template v-if="['/finance/withdrawal'].includes($route.path)">
        <!-- {{ $t('mine.index.252217-24') }} -->
        <div class="user-jv">
          <div class="title">
            <span class="text">{{ $t('mine.index.252217-24') }} </span>
            <span class="more" @click="$router.push('/record/transRecord?type=withdraw')">{{ $t('mine.index.252217-6')
            }}<i></i></span>
          </div>
          <ul class="pay-jl-list" v-if="ckList && ckList.length">
            <li v-for="(item, index) in ckList" :key="index">
              <span class="name">
                {{ item.withdrawMethodName }}
              </span>
              <p class="row">
                <span class="left" v-if="item.withdrawAmount">{{
                  item.withdrawAmount.toFixed(2)
                }}</span>
                <span class="right">{{ item.statusName }}</span>
              </p>
            </li>
          </ul>
          <div v-else class="user-jv-node">{{ $t('mine.index.252217-25') }}</div>
        </div>
        <!--  {{ $t('mine.index.252217-14') }} -->
        <div class="user-jv user-cark">
          <div class="title">
            <span class="text">{{ $t('mine.index.252217-18') }}</span>
            <span class="more" @click="openHelp(0)">{{ $t('mine.index.252217-6') }}<i></i></span>
          </div>
          <div class="help-list">
            <ul>
              <li @click="openHelp(30)">{{ $t('mine.index.477564-0') }}</li>
              <li @click="openHelp(160)">{{ $t('mine.index.477564-1') }}</li>
              <li @click="openHelp(240)">{{ $t('mine.index.477564-2') }}</li>
              <li @click="openHelp(400)">{{ $t('mine.index.477564-3') }}</li>
              <li @click="openHelp(500)">{{ $t('mine.index.477564-4') }}</li>
              <li @click="openHelp(590)">{{ $t('mine.index.477564-5') }}</li>
              <li @click="openHelp(670)">{{ $t('mine.index.477564-6') }}</li>
              <li @click="openHelp(800)">{{ $t('mine.index.477564-7') }}</li>
              <li @click="openHelp(900)">{{ $t('mine.index.477564-8') }}</li>
              <li @click="openHelp(1000)">{{ $t('mine.index.477564-9') }}</li>
              <li @click="openHelp(1000)">{{ $t('mine.index.477564-10') }}</li>
              <li @click="openHelp(1000)">{{ $t('mine.index.477564-11') }}</li>
            </ul>
          </div>
        </div>
      </template>
      <!-- {{ $t('mine.index.252217-43') }} -->
      <template v-if="[
        '/finance/wallet',
        '/addBankCard',
        '/virtualCoinCard',
        '/ebpayAccount',
      ].includes($route.path)
        ">
        <!-- {{ $t('mine.index.252217-5') }} -->
        <div class="user-jv">
          <div class="title">
            <span class="text">{{ $t('mine.index.252217-5') }}</span>
            <span class="more" @click="$router.push('/record/betRecord')">{{ $t('mine.index.252217-6') }}<i></i></span>
          </div>
          <ul class="pay-jl-list" v-if="recordList && recordList.length">
            <li v-for="(item, index) in recordList" :key="index">
              <span class="name">{{ item.gameName }}</span>
              <p class="row">
                <span class="left" v-if="item.validBetAmount">{{
                  item.validBetAmount.toFixed(2)
                }}</span>
                <span class="right">{{ getState(item.status) }}</span>
              </p>
            </li>
          </ul>
          <div v-else class="user-jv-node">{{ $t('mine.index.252217-7') }}</div>
        </div>
        <!--  {{ $t('mine.index.252217-14') }} -->
        <div class="user-jv user-cark">
          <div class="title">
            <span class="text">{{ $t('mine.index.252217-14') }}</span>
            <span class="more" @click="$router.push('/activity/discount')">{{ $t('mine.index.252217-6') }}<i></i></span>
          </div>
          <div class="discount-list">
            <ul v-if="activityList">
              <li v-for="(item, index) in activityList" :key="index" @click="
                $router.push('/activity/activityLandPage?type=' + item.id)
                ">
                <img :src="item.listImgUrl" />
              </li>
            </ul>
            <div v-else class="user-jv-node">{{ $t('mine.index.252217-15') }}</div>
          </div>
        </div>
      </template>

      <!-- {{ $t('mine.index.252217-40') }} -->
      <template v-if="['/feedback'].includes($route.fullPath)">
        <!-- {{ $t('mine.index.252217-5') }} -->
        <div class="user-jv">
          <div class="title">
            <span class="text">{{ $t('mine.index.252217-37') }}</span>
            <span class="more" @click="$router.push('/feedback/list')">{{ $t('mine.index.252217-6') }}<i></i></span>
          </div>
          <div class="user-jv-list" v-if="facebookList && facebookList.length">
            <ul>
              <li v-for="(item, index) in facebookList" :key="index" @click="openFacebook(item)">
                <span>{{ typeName(item.opinionType) }}</span>
                <p>
                  {{ item.problemDescription }}
                </p>
              </li>
            </ul>
          </div>

          <div class="user-jv-node" v-else>{{ $t('mine.index.252217-38') }}</div>
        </div>
      </template>
    </div>
    <SetPhoto v-if="isExitPhoto && userInfo && userInfo.photoUrl" :visible.sync="isExitPhoto"
      :title="$t('mine.index.252217-39')" />
    <helpCenter v-if="isHelp" :visible.sync="isHelp" :scrollTop="helpTop" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getTime } from "@/mixins/getTime";
import SetPhoto from "@/components/SetPhoto.vue";
import helpCenter from '@/components/TransferHelp.vue'
export default {
  components: { SetPhoto, helpCenter },
  data() {
    return {
      isHelp: false,
      helpTop: 0,
      menuInd: undefined,
      menus: [
        [
          {
            name: this.$t('mine.index.252217-40'),
            link: "/mine/userInfo",
            aid: 1,
            icon: require("@/assets/images/mine/icon04.png"),
            iconHover: require("@/assets/images/mine/icon04-1.png"),
          },
          {
            name: this.$t('mine.index.252217-41'),
            link: "/mine/vip",
            aid: 2,
            icon: require("@/assets/images/mine/icon05.png"),
            iconHover: require("@/assets/images/mine/icon05-1.png"),
          },
          {
            name: this.$t('mine.index.252217-42'),
            link: "/record/welfare",
            aid: 3,
            icon: require("@/assets/images/mine/icon06.png"),
            iconHover: require("@/assets/images/mine/icon06-1.png"),
          },
        ],
        [
          {
            name: this.$t('mine.index.252217-43'),
            link: "/finance/wallet",
            aid: 4,
            icon: require("@/assets/images/mine/icon07.png"),
            iconHover: require("@/assets/images/mine/icon07-1.png"),
          },
          {
            name: this.$t('mine.index.252217-44'),
            link: "/record/transRecord",
            aid: 5,
            icon: require("@/assets/images/mine/icon08.png"),
            iconHover: require("@/assets/images/mine/icon08-1.png"),
          },
          {
            name: this.$t('mine.index.252217-5'),
            link: "/record/betRecord",
            aid: 6,
            icon: require("@/assets/images/mine/icon09.png"),
            iconHover: require("@/assets/images/mine/icon09-1.png"),
          },
        ],
        [
          {
            name: this.$t('mine.index.252217-45'),
            link: "/record/giftRecord",
            aid: 7,
            icon: require("@/assets/images/mine/icon10.png"),
            iconHover: require("@/assets/images/mine/icon10-1.png"),
          },
          {
            name: this.$t('mine.index.252217-46'),
            link: "/mail",
            aid: 8,
            icon: require("@/assets/images/mine/icon11.png"),
            iconHover: require("@/assets/images/mine/icon11-1.png"),
          },
          {
            name: this.$t('mine.index.252217-47'),
            link: "/feedback",
            aid: 9,
            icon: require("@/assets/images/mine/icon12.png"),
            iconHover: require("@/assets/images/mine/icon12-1.png"),
          },
        ],
      ],
      isExitPhoto: false,
      carkInd: 0,
      isShowRight: false,
      isHover: "",
      recordList: null, //投注列表
      // 卡片参数
      bankListMenu: [
        {
          name: this.$t('mine.index.252217-8'),
          type: 1,
          moreLink: "/finance/wallet",
          addLink: "/addBankCard",
        },
        {
          name: this.$t('mine.index.252217-9'),
          type: 2,
          moreLink: "/virtualCoinCard",
          addLink: "/virtualCoinCard",
        },
      ],
      bankList: null,
      // 存款参数
      ckList: null,
      // 反馈列表
      facebookList: null,
      // 活动列表
      activityList: null,
    };
  },
  watch: {
    $route(val) {
      if (val.meta.isShow) {
        this.isShowRight = true;
      } else {
        this.isShowRight = false;
      }

      this.infoLeftVal();
      this.initPost();
    },
    isLogin(val) {
      if (val) {
        this.getUserInfo()
      }
    },
  },
  computed: {
    ...mapState(["userInfo", "message"]),
  },
  mounted() {
    this.infoRoute();
    this.infoLeftVal();
    this.getUnReadMessageCount();
    this.initPost();
  },
  methods: {
    openHelp(val) {
      this.isHelp = true
      document.body.style = 'overflow:hidden'
      this.helpTop = val
    },
    // 个人资料
    getUserInfo() {
      this.$Api.getUserInfo().then((res) => {
        if (res) {
          this.$store.dispatch("setUserInfoAc", res.userInfo);
        }
      });
    },
    // 设置头像
    setPhoto() {
      this.isExitPhoto = true;
    },
    // 切换银行卡
    tabBankList(index) {
      this.carkInd = index;
      if (index === 0) {
        this.cardPost();
      } else {
        this.cardPostUSDT();
      }
    },
    // 初始化调对应接口
    initPost() {
      const url = this.$route.path;
      // 个人资料
      if (url === "/mine/userInfo") {
        this.tqPost(); //投注接口
        this.cardPost(); //银行卡接口
      }
      if (url === "/finance/wallet") {
        this.tqPost(); //投注接口
      }
      if (url === "/finance/deposit") {
        this.ckPost(); //存款接口
      }
      if (url === "/finance/transfer") {
        this.ckPost("transfer"); //转账
      }
      if (url === "/finance/withdrawal") {
        this.ckPost("withdraw"); //取款
      }
      if (url === "/feedback") {
        this.getFeedbackList();
      }
      if (["/finance/deposit", "/finance/wallet"].includes(url)) {
        this.getActivityList();
      }
    },
    // 活动列表
    getActivityList() {
      const params = {
        activityClassId: "",
        device: 1,
        pageIndex: 1,
        pageSize: 5,
      };
      this.$Api.getActivityList(params).then((res) => {
        if (res) {
          this.activityList = res.activityList.records;
        }
      });
    },
    // 反馈列表
    getFeedbackList() {
      const params = {
        pageIndex: 1,
        pageSize: 20,
      };
      this.$Api.getFeedbackList(params).then((res) => {
        if (res) {
          this.facebookList = res.page.list;
        }
      });
    },
    openFacebook(val) {
      this.$router.push("/feedback/detail?order=" + val.id);
    },
    // 存款、转账、取款接口
    ckPost(val) {
      const params = {
        transType: val ? val : "recharge",
        startTime: getTime(),
        endTime: getTime(),
        pageIndex: 1,
        pageSize: 5,
      };
      this.$Api.getUserTransInfo(params).then((res) => {
        if (res) {
          this.ckList = res.page.records;
        }
      });
    },
    // 卡片列表
    cardPost() {
      this.$Api.queryUserBankCardList().then((res) => {
        if (res) {
          res.userBankCardList.forEach((item) => {
            item.cardType = item.cardType === 1 ? this.$t('mine.index.252217-48') : this.$t('mine.index.252217-49');
          });
          this.bankList = res.userBankCardList;
          if (res.userBankCardList.length) {
            this.$store.dispatch('setIsBankLengthAc', true)
            console.log(this.$store.state);
          }
        }
      });
    },
    cardPostUSDT() {
      this.$Api.queryUserBlockchainList().then((res) => {
        if (res) {
          res.userBlockchainList.forEach((item) => {
            item.iconUrl = require("@/assets/images/pay/xnt.png");
            item.bankName = item.name;
            item.bankcardNo = item.address;
            item.cardType = item.networkType;
            item.class = "usdt";
          });
          this.bankList = res.userBlockchainList;
        }
      });
    },
    // 投注接口
    tqPost() {
      const params = {
        platType: "",
        startTime: getTime(),
        endTime: getTime(),
        pageIndex: 1,
        pageSize: 5,
      };
      this.$Api.getBetRecord(params).then((res) => {
        if (res) {
          this.recordList = res.page.records;
        }
      });
    },
    // 投注状态
    getState(e) {
      const arr = [this.$t('mine.index.252217-50'), this.$t('mine.index.252217-51'), this.$t('mine.index.252217-52')];
      return arr[e];
    },
    // message
    getUnReadMessageCount() {
      this.$Api.getUnReadMessageCount().then((res) => {
        if (res) {
          const message =
            res.data.totalCount > 99 ? "99+" : res.data.totalCount;
          this.$store.dispatch("setMessageAc", message);
        }
      });
    },
    infoLeftVal() {
      if (
        [
          "/finance/deposit",
          "/finance/transfer",
          "/finance/withdrawal",
        ].includes(this.$route.fullPath)
      ) {
        this.isHover = this.$route.fullPath;
        this.menuInd = undefined;
      } else {
        this.isHover = "";
      }
      this.menus.map((item) => {
        if ("/mine/details" === this.$route.fullPath) {
          this.menuInd = 2;
          return;
        }
        item.find((row) => {
          if (row.link === this.$route.fullPath) {
            this.menuInd = row.aid;
          }
        });
      });
    },
    infoRoute() {
      if (this.$route.meta.isShow) {
        this.isShowRight = true;
      } else {
        this.isShowRight = false;
      }
    },
    tabMenu(item) {
      this.menuInd = item.aid;
      this.$router.push(item.link);
    },
    convertType(val) {
      let textVal = "";
      switch (val) {
        case 1:
          textVal = this.$t('mine.index.252217-53');
          break;
        case 2:
          textVal = this.$t('mine.index.252217-54');
          break;
        case 3:
          textVal = this.$t('mine.index.252217-55');
          break;
        case 4:
          textVal = this.$t('mine.index.252217-56');
          break;
        case 5:
          textVal = this.$t('mine.index.252217-57');
          break;
        case 6:
          textVal = this.$t('mine.index.252217-58');
          break;
      }
      return textVal;
    },
    typeName(val) {
      const options = [
        {
          value: 1,
          label: this.$t('mine.index.252217-59'),
        },
        {
          value: 2,
          label: this.$t('mine.index.252217-60'),
        },
        {
          value: 3,
          label: this.$t('mine.index.252217-61'),
        },
        {
          value: 4,
          label: this.$t('mine.index.252217-62'),
        },
        {
          value: 5,
          label: this.$t('mine.index.252217-63'),
        },
        {
          value: 6,
          label: this.$t('mine.index.252217-64'),
        },
        {
          value: 7,
          label: this.$t('mine.index.252217-65'),
        },
        {
          value: 8,
          label: this.$t('mine.index.252217-66'),
        },
        {
          value: 9,
          label: this.$t('mine.index.252217-67'),
        },
      ];
      return options.find((item) => item.value === val).label;
    },
  },
};
</script>

<style lang="scss" scoped>
.vipCenter {
  display: flex;
  padding-top: 100px;
  overflow: hidden;
}

.user-left {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(152, 161, 182, 0.04);
  background-color: #fff;
  width: 220px;
  height: auto;
  padding-top: 24px;
  margin-right: 16px;
  flex: none;
}

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .photo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    &:hover {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url("@/assets/images/xj.png") no-repeat rgba(0, 0, 0, 0.3) center center;
        background-size: 24px auto;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .name {
    display: flex;
    align-items: center;
    margin-top: 12px;

    .text {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      color: #788b9d;
      font-weight: 400;
      font-family: DIN, PingFangSC-Regular, sans-serif !important;
    }

    .level {
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
      cursor: pointer;
      width: 52px;
      height: 24px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .time {
    height: 17px;
    line-height: 17px;
    font-size: 12px;
    margin-top: 4px;
    color: #788b9d;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px 24px;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      &:hover,
      &.hover {
        .icon {
          .xz {
            display: block;
          }

          .mr {
            display: none;
          }
        }

        p {
          color: #2599f8;
        }
      }

      .icon {
        position: relative;
        width: 32px;
        height: 32px;

        img {
          width: 100%;
        }

        .xz {
          display: none;
        }
      }
    }
  }
}

.user-nav {
  padding: 0 12px;

  ul {
    padding: 16px 0;
    border-bottom: 1px solid #e4e8eb;

    &:last-child {
      border-bottom: none;
    }

    li {
      position: relative;
      overflow: hidden;
      background: transparent;
      border-left: 3px solid transparent;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #788b9d;
      height: 52px;

      &.hover,
      &:hover {
        background: #f4faff;
        border-radius: 12px;

        .icon {
          .xz {
            display: block;
          }

          .mr {
            display: none;
          }
        }

        .text {
          color: #2599f8;
        }
      }

      .icon {
        width: 20px;
        margin: 0 10px 0 16px;

        img {
          width: 100%;
        }

        .xz {
          display: none;
        }
      }
    }
  }
}

.user-jv {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(152, 161, 182, 0.04);
  background-color: #fff;
  width: 200px;
  height: auto;
  min-height: 360px;
  padding: 16px 14px;

  &:last-child {
    flex: 1 1;
  }

  .title {
    display: flex;
    justify-content: space-between;

    .text {
      color: #30383f;
      text-align: justify;
      font-family: PingFangSC-Medium;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }

    .more {
      color: #2599f8;
      font-size: 12px;
      display: flex;
      align-items: center;
      cursor: pointer;

      i {
        width: 7px;
        height: 7px;
        transform: rotate(45deg);
        border-top: 1px solid #2599f8;
        border-right: 1px solid #2599f8;
      }
    }
  }

  &-node {
    font-size: 12px;
    line-height: 17px;
    margin-top: 150px;
    text-align: center;
    color: #788b9d;

    span {
      color: #2599f8;
      cursor: pointer;
    }
  }
}

.cark-title {
  ul {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      content: "";
      border-bottom: 1px solid #f0f0f0;
    }

    li {
      color: #48535e;
      text-align: center;
      height: 30px;
      padding: 2px 0 12px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &.hover {
        color: #2599f8;
        border-bottom: 2px solid #2599f8;
      }
    }
  }
}

.user-cark {
  margin-top: 16px;
  min-height: 460px;
  flex: 1 1;
}

.user-right {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  flex: none;
  z-index: 1;
}

.discount-list {
  margin-top: 12px;

  li {
    width: 100%;
    margin-bottom: 12px;
    cursor: pointer;

    img {
      width: 100%;
    }
  }
}

.pay-jl-list {
  li {
    margin-top: 8px;
    padding: 12px 8px;
    height: 64px;
    border-bottom: none;

    .name {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      height: 17px;
      color: #788b9d;
      margin-bottom: 4px;
      display: flex;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
        margin: 0 4px;
      }
    }

    .row {
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 19px;
      height: 19px;
      color: #30383f;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        font-family: Akrobat;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 19px;
        height: 19px;
        color: #30383f;
      }

      .right {
        background: rgba(175, 179, 200, 0.2);
        border-radius: 2px;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #aeb9c4;
        padding: 4px;

        &.success {
          color: #4ab06a;
          background: rgba(74, 176, 106, 0.2);
        }
      }
    }
  }
}

.help-list {
  margin-top: 20px;

  li {
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #48535e;
  }
}

.user-jv-list {
  ul {
    li {
      border-bottom: 1px solid #f2f2f6;
      padding: 12px 0 8px;
      cursor: pointer;

      span {
        color: #a5a9b3;
        font-size: 12px;
        line-height: 12px;
      }

      p {
        line-height: 20px;
        margin-top: 2px;
        color: #414655;
        font-size: 12px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}

.message {
  position: absolute;
  right: 28px;
  top: 19px;
  min-width: 16px;
  height: 16px;
  text-align: center;
  border-radius: 8px;
  padding: 0 5px;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  background: #179cff;
}

.bankList-right {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding: 10px 0;

  span {
    cursor: pointer;
  }
}

.card-list {
  li {
    width: 172px;
    height: 96px;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 4px 10px 0 rgba(240, 176, 176, 0.4);
    background-image: linear-gradient(298deg, #d57074, #f7acaf);
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    margin-bottom: 16px;

    &.usdt {
      background-image: linear-gradient(118deg, #4de2b8, #45ba99 99%);
      box-shadow: 0 4px 10px 0 rgba(72, 200, 164, 0.4);
    }

    &:last-child {
      margin-bottom: 0;
    }

    .sec-img {
      width: 28px;
      height: 28px;
      background-color: #fff;
      border-radius: 50%;
      display: inline-block;
      vertical-align: top;
      flex: none;
      overflow: hidden;
      padding: 6px;
    }

    .sec {
      color: white;
      font-size: 12px;
      margin-left: 8px;

      .sec-text {
        opacity: 0.5;
      }
    }

    .sec-bankNo {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 16px;
      text-align: center;
      color: white;
      font-size: 12px;
    }
  }
}
</style>
